import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CodeForgetComponent } from './containers/code-forget/code-forget.component';
import { RegisterComponent } from './containers/register/register.component';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { AddressGuard } from '../addresses/guards';

const routes: Routes = [
  {
    path: 'inloggen',
    component: SignInComponent,
    canActivate: [AddressGuard],
  },
  {
    path: 'registreren',
    component: RegisterComponent,
    canActivate: [AddressGuard],
  },
  {
    path: 'code-vergeten',
    component: CodeForgetComponent,
    canActivate: [AddressGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/aanmelden/registreren',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
