<app-auth-layout [text]="'inloggen-text' | fromDictionary" [title]="prefix + '-titel' | fromDictionary">
  <h1 class="font-secondary text-secondary fs-body-md" form>Inloggen</h1>
  <p form class="mb-4">{{ 'adres-formulier-intro-tekst' | fromDictionary }}</p>
  <!-- <app-alert form class="d-block mb-4" [status]="'info'" [message]="'adres-formulier-gegevens-info-melding' | fromDictionary"></app-alert> -->

  <app-address-form [address]="address$ | async" form #addressForm></app-address-form>

  <app-alert
    form
    class="d-block mb-4"
    [status]="'info'"
    *ngIf="
      (addressForm?.street$ | async) && (reloadForm | async) !== true && addressForm.addressForm.get('houseNumberAddition')?.value !== null
    "
    [message]="
      ((addressForm.street$ | async) && (addressForm.town$ | async)
        ? '<strong>' +
          (addressForm.street$ | async) +
          ' ' +
          addressForm.addressForm.get('houseNumber')?.value +
          (addressForm.addressForm.get('houseNumberAddition') ? '  ' + addressForm.addressForm.get('houseNumberAddition')?.value : '') +
          ', ' +
          (addressForm.town$ | async) +
          '.</strong> '
        : '') + ('adres-formulier-gegevens-info-melding' | fromDictionary)
    "
  ></app-alert>

  <!-- <app-alert
    form
    *ngIf="(addressForm.street$ | async) && (addressForm.town$ | async)"
    class="d-block mb-4"
    [status]="'info'"
    [message]="'Uw adres is <strong>' + (addressForm.street$ | async) + ' ' + (addressForm.town$ | async) + '</strong>'"
  ></app-alert> -->
  <fstr-dynamic-form
    *ngIf="(reloadForm | async) !== true"
    form
    class="theme-light"
    [appearance]="'fill'"
    [config]="formFields"
    (formSubmit)="handleSubmit($event)"
    [showSubmit]="false"
    #form
  >
    <div slot="form-alert" *ngIf="error$ | async as error">
      <app-alert
        [status]="'danger'"
        [message]="error.error.errors?.[0]?.description || (getErrorMessage(error?.error) | fromDictionary)"
      ></app-alert>
    </div>

    <footer slot="form-actions" class="d-flex align-items-md-end flex-column sign-in__footer pb-6 pb-md-4">
      <div class="d-flex flex-column-reverse flex-md-row justify-content-md-end align-items-md-center">
        <div class="d-flex align-items-center me-md-2" *ngIf="signingIn$ | async">
          <p class="mb-0 type-body-xxs text-primary">{{ prefix + '-formulier-laden-tekst' | fromDictionary }}</p>
          <span class="chip chip--md">
            <fstr-icon class="icon--rotating" anchor="icon-loading"></fstr-icon>
          </span>
        </div>
        <button
          (click)="handleSubmit(form?.value)"
          type="button"
          class="button button--primary"
          [disabled]="!form?.form?.valid || !addressForm?.addressForm?.valid || (signingIn$ | async)"
        >
          <span>{{ prefix + '-formulier-verzenden-knop-tekst' | fromDictionary }}</span>
        </button>
      </div>
      <div class="type-body-xxs mt-3" *ngIf="codeFieldMode">
        Nog geen account?
        <a class="button button-link button--xxs button--secondary" [routerLink]="['/', 'aanmelden', 'registreren']">
          <span>{{ 'inloggen-registreren-label' | fromDictionary }}</span>
        </a>
        |
        <a class="button button-link button--xxs button--secondary" [routerLink]="['/', 'aanmelden', 'code-vergeten']">
          {{ 'inloggen-geen-code-label' | fromDictionary }}
        </a>
      </div>
    </footer>
  </fstr-dynamic-form>
</app-auth-layout>
