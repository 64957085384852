import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, lastValueFrom, map, of, take, takeUntil, tap } from 'rxjs';
import { LoginData } from '../../models';
import { getAuthError, getAuthSignedIn, getAuthSigningIn, RemoveErrors, SignIn } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Go, SetTitle } from '../../../store';
import { DynamicFormField, FormComponent } from '@teamfoster/dynamic-forms';
import { AddressFormComponent } from 'src/app/addresses/components/address-form/address-form.component';
import { SetAddress } from 'src/app/addresses/store/actions/address.action';
import { Address } from 'src/app/addresses/models';
import { getAddress } from 'src/app/addresses/store';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit {
  signingIn$: Observable<boolean> = of(false);
  signedIn$: Observable<boolean> = of(false);
  error$?: Observable<any>;
  prefix = 'inloggen';

  codeFieldMode = false;

  signInForm = this.fb.group({});
  formFields: DynamicFormField[] = [];
  address$!: Observable<Address | null>;

  @ViewChild('addressForm', { static: false }) addressForm!: AddressFormComponent;
  @ViewChild('form', { static: false }) form!: FormComponent;

  reloadForm = new Subject();

  private dict = new FromDictionaryPipe(this.lang);

  cd = inject(ChangeDetectorRef);

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private lang: LanguageService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.formFields = [];

    var snapshot = this.route.snapshot;
    var address: Address = {
      addition: snapshot.queryParams['addition'],
      postalCodeFull: snapshot.queryParams['pc'],
      houseNumber: snapshot.queryParams['number'],
      city: '',
      street: '',
      province: '',
      municipality: '',
      latLng: { lat: 0, lng: 0 },
      hasWasteCard: false,
    };

    // All params are present as query params
    var addressOverride = ['number', 'pc', 'number'].every(a => Object.keys(snapshot.queryParams).indexOf(a) >= 0);

    this.store.dispatch(RemoveErrors());
    this.address$ = addressOverride ? of(address) : this.store.select(getAddress);

    this.signingIn$ = this.store.select(getAuthSigningIn);
    this.signedIn$ = this.store.select(getAuthSignedIn);
    this.error$ = this.store.select(getAuthError);

    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(`${this.prefix}-titel`)} - ${this.dict.transform('meta-titel-suffix')}` })
    );

    this.route.queryParams.subscribe(a => {
      const returnUrl = a['returnUrl'];
      this.codeFieldMode = returnUrl === '/inzicht';

      this.reloadForm.next(true);

      if (this.codeFieldMode) {
        this.enableCodeField(snapshot.queryParams['code']);
      } else {
        this.disableCodeField();
      }

      setTimeout(() => {
        this.reloadForm.next(false);
        this.cd.detectChanges();
      }, 1);
    });

    var signedIn = await lastValueFrom(this.signedIn$);
    if (signedIn) {
      this.store.dispatch(Go({ path: [this.route.snapshot.queryParams['returnUrl'] || '/mijn-rova'] }));
    }
  }

  enableCodeField(codeFromParams: string | null) {
    const inzichtCode: DynamicFormField = {
      inputType: 'text',
      label: this.dict.transform(`${this.prefix}-formulier-veld-label-code`),
      name: 'code',
      validators: [Validators.required],
      autocomplete: 'off',
      placeholder: this.dict.transform(`${this.prefix}-formulier-veld-placeholder-code`),
      hint: this.dict.transform(`${this.prefix}-formulier-veld-hint-code`, false),
      fieldType: 'input',
      value: codeFromParams,
      order: 1,
      cssClass: 'mb-3',
    };
    const rememberInzichtCode: DynamicFormField = {
      name: 'rememberCode',
      checkboxLabel: this.dict.transform(`${this.prefix}-formulier-veld-label-code-onthouden`),
      fieldType: 'checkbox',
      order: 2,
      cssClass: 'mb-3',
    };

    this.formFields = [...[inzichtCode, rememberInzichtCode].concat(this.formFields)];
  }

  disableCodeField() {
    this.formFields = this.formFields.filter(a => a.name !== 'code' && a.name !== 'rememberCode');
  }

  handleSubmit(e: any) {
    const loginData: LoginData = { ...this.addressForm.addressForm.value, ...e };
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (returnUrl === '/inzicht') {
      loginData.code = loginData.code.toUpperCase();
      this.store.dispatch(SignIn({ loginData, rememberCode: e.rememberCode }));
    } else {
      const { postalCode, houseNumber, houseNumberAddition } = loginData;
      this.store.dispatch(SetAddress({ postalCode, housenumber: houseNumber, addition: houseNumberAddition }));
    }
  }

  getErrorMessage(error: { succeeded: boolean; isLockedOut: boolean; isNotAllowed: boolean; requiresTwoFactor: boolean }) {
    if (error?.isNotAllowed) {
      return `${this.prefix}-formulier-error-tekst-activatie`;
    }

    if (error?.isLockedOut) {
      return `${this.prefix}-formulier-error-tekst-disabled`;
    }

    return `${this.prefix}-formulier-error-default`;
  }
}
