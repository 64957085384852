import { ChangeDetectionStrategy, Component, Signal, ViewChild, computed, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ClearInsightCode,
  SetInsightCode,
  getAuthError,
  getInsightCode,
  getInsightCodeError,
  getInsightCodeLoaded,
  getInsightCodeLoading,
} from '../../store';
import { DynamicFormField, FormComponent } from '@teamfoster/dynamic-forms';
import { Validators } from '@angular/forms';
import { Address, getAddress } from 'src/app/addresses';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-insight-code-form',
  templateUrl: './insight-code-form.component.html',
  styleUrl: './insight-code-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightCodeFormComponent {
  private store = inject(Store);

  address$ = this.store.selectSignal(getAddress);
  insightCode$ = this.store.selectSignal(getInsightCode);
  error$ = this.store.selectSignal(getAuthError);
  insightCodeLoading$ = this.store.selectSignal(getInsightCodeLoading);
  insightCodeLoaded$ = this.store.selectSignal(getInsightCodeLoaded);

  @ViewChild('form') form!: FormComponent;

  dict = inject(FromDictionaryPipe);

  formFields$: Signal<DynamicFormField[]> = computed(() =>
    this.address$()
      ? [
          {
            fieldType: 'text',
            label: 'ROVA Inzicht code',
            name: 'insightCode',
            validators: [Validators.required],
            placeholder: this.dict.transform('inzicht-code-formulier-veld-placeholder', false),
            asyncValidators: [],
            order: 1,
            subscriptSizing: 'dynamic',
            updateOn: 'blur',
            value: this.insightCode$(),
          },
        ]
      : []
  );

  save(address: Address, code: string) {
    if (this.form.form.valid) {
      this.store.dispatch(
        SetInsightCode({
          housenumber: +address.houseNumber,
          addition: address.addition || '',
          postalCode: address.postalCodeFull,
          code,
        })
      );
    }
  }

  clear() {
    this.store.dispatch(ClearInsightCode());
  }
}
