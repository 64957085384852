import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getResetError, getResetting, getResetSuccess, ResetCode, RemoveErrors } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTitle } from '../../../store';
import { DynamicFormField, FormComponent } from '@teamfoster/dynamic-forms';
import { AddressFormComponent } from 'src/app/addresses/components/address-form/address-form.component';
import { RegisterData } from '../../models';
import { Address } from 'src/app/addresses/models';
import { getAddress } from 'src/app/addresses/store';
import { SetAddress } from 'src/app/addresses/store/actions/address.action';

@Component({
  selector: 'app-code-forget',
  templateUrl: './code-forget.component.html',
})
export class CodeForgetComponent implements OnInit {
  resetting$: Observable<boolean> = of(false);
  resetSuccess$: Observable<boolean> = of(false);
  resetError$: Observable<any> = of();
  address$!: Observable<Address | null>;

  prefix = 'registreren';
  formValid = false;
  private dict = new FromDictionaryPipe(this.lang);

  activeTab = 0;

  @ViewChild('addressForm', { static: false }) addressForm!: AddressFormComponent;
  @ViewChild('form', { static: false }) form!: FormComponent;

  formFields: DynamicFormField[] = [];

  constructor(
    private fb: FormBuilder,
    private lang: LanguageService,
    private store: Store,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.address$ = this.store.select(getAddress);
    this.store.dispatch(RemoveErrors());
    this.formFields = [
      {
        fieldType: 'checkbox',
        label: '',
        checkboxLabel: this.dict.transform(`${this.prefix}-voorwaarden-label`),
        name: 'consent',
        validators: [Validators.requiredTrue],
        order: 1,
        fieldset: 'step1',
        cssClass: 'hide--label',
      },
    ];

    this.resetSuccess$ = this.store.select(getResetSuccess);
    this.resetting$ = this.store.select(getResetting);
    this.resetError$ = this.store.select(getResetError).pipe(
      tap(error => {
        if (!error?.ok) {
          window.scrollTo(0, 0);
        }
      })
    );
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(this.prefix + '-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  get addressLine$(): Observable<string> | undefined {
    if (!this.addressForm || !this.addressForm.addressForm.value) {
      return undefined;
    }

    const formValues = this.addressForm.addressForm.value;
    return combineLatest([this.addressForm.street$, this.addressForm.town$]).pipe(
      map(
        a =>
          `${a[0]} ${formValues['houseNumber']} ${formValues['houseNumberAddition'] ? ' - ' + formValues['houseNumberAddition'] : ''}, ${
            a[1]
          }`
      )
    );
  }

  handleSubmit(e: any) {
    const registerData: RegisterData = { ...this.addressForm.addressForm.value, ...e };
    this.store.dispatch(ResetCode({ registerData }));
    // store new address to localstorage
    this.store.dispatch(
      SetAddress({
        housenumber: +registerData.houseNumber,
        postalCode: registerData.postalCode,
        addition: registerData.houseNumberAddition ? registerData.houseNumberAddition : '',
        preventRedirect: true,
      })
    );
  }
}
