<app-auth-layout [title]="prefix + '-titel' | fromDictionary" [text]="prefix + '-tekst' | fromDictionary" [backUrl]="'/aanmelden/inloggen'">
  <ng-container form>
    <app-stepper
      [activeTab]="activeTab"
      [disableNextTabs]="!(addressForm.valid$ | async) || !form.form.valid || false"
      #stepper
      (selectedTab)="activeTab = $event"
    >
      <app-step [title]="'Adres'">
        <h2 class="font-secondary text-secondary fs-body-md">{{ 'registreren-formulier-gegevens-titel' | fromDictionary }}</h2>
        <p class="mb-5 text-max-width-xs">{{ 'registreren-formulier-gegevens-tekst' | fromDictionary }}</p>

        <!-- Adres -->
        <app-address-form [address]="address$ | async" #addressForm></app-address-form>
        <!-- Overige velden -->
        <fstr-dynamic-form [config]="formFields" [appearance]="'fill'" [showSubmit]="false" #form></fstr-dynamic-form>
        <!-- <app-alert class="d-block mb-4" [status]="'info'" [message]="'adres-formulier-gegevens-info-melding' | fromDictionary"></app-alert> -->

        <div class="d-flex">
          <button
            class="button button--primary ms-auto"
            (click)="stepper.go('next')"
            [disabled]="!(addressForm.valid$ | async) || !form.form.valid"
          >
            Ga verder
          </button>
        </div>
      </app-step>
      <app-step [title]="'Controleren'">
        <h2 class="font-secondary text-secondary fs-body-md">{{ 'registreren-formulier-gegevens-controleren-titel' | fromDictionary }}</h2>
        <p class="mb-5 text-max-width-xs">
          {{ 'registreren-formulier-gegevens-controleren-tekst' | fromDictionary }}
        </p>

        <article class="panel bg-space-gray-200 mb-5">
          <figure>
            <span class="chip chip--secondary">
              <fstr-icon anchor="icon-letter-small"></fstr-icon>
            </span>
          </figure>
          <main class="panel__main">
            <strong class="text-secondary">{{ 'registreren-formulier-gegevens-controleren-card-titel' | fromDictionary }}</strong>
            <p class="mb-0">{{ addressLine$ | async }}</p>
          </main>
          <div class="panel__actions">
            <button class="link fw-bold me-md-2" (click)="stepper.go('prev')">Aanpassen</button>
          </div>
        </article>

        <div class="d-flex">
          <button class="button button--primary ms-auto" (click)="stepper.go('next'); handleSubmit(true)">Ga verder</button>
        </div>
      </app-step>
      <app-step class="text-center" [title]="'Geslaagd!'">
        <ng-container *ngIf="registering$ | async">
          <span class="chip chip--xl mt-6 mb-8">
            <fstr-icon class="icon--rotating" anchor="icon-loading"></fstr-icon>
          </span>
          <p>{{ prefix + '-formulier-laden-tekst' | fromDictionary }}</p>
        </ng-container>
        <ng-container *ngIf="registerError$ | async">
          <span class="chip chip--danger chip--xl mt-6 mb-2">
            <fstr-icon anchor="icon-alert-warning"></fstr-icon>
          </span>
        </ng-container>
        <ng-container *ngIf="registerSuccess$ | async">
          <span class="chip chip--primary chip--xl chip--pulsing chip--success mt-6 mb-8" *ngIf="activeTab === 2">
            <fstr-icon anchor="icon-check"></fstr-icon>
          </span>
          <p class="mb-5 text-max-width-xs mx-auto h6 font-secondary text-secondary">
            {{ 'registreren-formulier-gegevens-geslaagd-tekst' | fromDictionary }}
          </p>

          <div class="d-flex">
            <a class="button button--primary mx-auto" href="https://www.rova.nl/mijn-rova">Terug naar website</a>
          </div>
        </ng-container>

        <app-alert
          class="d-block mt-4 mb-4 text-start"
          *ngIf="registerError$ | async as errors"
          [status]="'danger'"
          [title]="prefix + '-formulier-error-titel' | fromDictionary"
          [message]="
            (errors.error === null || errors?.error?.length === 0) && errors?.status === 500
              ? (prefix + '-formulier-error-tekst' | fromDictionary)
              : undefined
          "
        >
          <ng-container>
            <ul *ngIf="errors?.errors?.length > 1">
              <li class="type-body-xxs" *ngFor="let error of errors.error.errors">
                {{ error.description }}
                <p *ngIf="error.code === 'ADDRESS_DUPLICATE'">
                  Vraag
                  <a href="aanmelden/code-vergeten/">hier</a>
                  een nieuwe code aan.
                </p>
              </li>
            </ul>
            <div *ngIf="errors?.errors?.length < 2">
              <div class="type-body-xxs mb-0" *ngFor="let error of errors.error.errors">
                {{ error.description }}
                <div *ngIf="error.code === 'ADDRESS_DUPLICATE'">
                  Vraag
                  <a href="aanmelden/code-vergeten/">hier</a>
                  een nieuwe code aan.
                </div>
              </div>
            </div>
          </ng-container>
        </app-alert>

        <div class="d-flex" *ngIf="registerError$ | async">
          <button class="button button--danger mx-auto" (click)="stepper.go(0)">Terug</button>
        </div>
      </app-step>
    </app-stepper>

    <!--<app-form-stepper
      [appearance]="'fill'"
      [config]="formFields || []"
      [fieldsets]="fieldsets"
      (formSubmit)="handleSubmit($event)"
      [submitButtonLabel]="'Verzenden'"
      [stepsTitle]="'stepsTitle'"
    ></app-form-stepper>-->
  </ng-container>
</app-auth-layout>
