import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { AuthRoutingModule } from './auth-routing.module';
import { RegisterComponent } from './containers/register/register.component';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule, FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import * as fromAuth from './store';
import { RouterModule } from '@angular/router';
import { DynamicFormModule } from '@teamfoster/dynamic-forms';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { IconModule } from '@teamfoster/sdk/icon';
import { FormModule } from '../form/form.module';

import { AddressesModule } from '../addresses/addresses.module';
import { ImageModule } from '@teamfoster/sdk/image';
import { CodeForgetComponent } from './containers/code-forget/code-forget.component';
import { StepComponent } from '../standalone/step/step.component';
import { StepperComponent } from '../standalone/stepper/stepper.component';
import { AlertComponent } from '../standalone/alert/alert.component';
import { InsightCodeFormComponent } from './components/insight-code-form/insight-code-form.component';
@NgModule({
  declarations: [SignInComponent, RegisterComponent, AuthLayoutComponent, CodeForgetComponent, InsightCodeFormComponent],
  imports: [
    AuthRoutingModule,
    CommonModule,
    DynamicFormModule,
    CoreModule,
    FormModule,
    TextUtilityModule,
    IconModule,
    ImageModule,
    StepComponent,
    StepperComponent,
    AlertComponent,
    DictionaryNgrxModule,
    RouterModule,
    MatSnackBarModule,
    AddressesModule,
    StoreModule.forFeature(fromAuth.featureKey, fromAuth.reducers, {
      metaReducers: fromAuth.metaReducers,
    }),
    EffectsModule.forFeature(fromAuth.effects),
  ],
  exports: [InsightCodeFormComponent],
  providers: [FromDictionaryPipe],
})
export class AuthModule {}
