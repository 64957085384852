@if (insightCode$() && !error$()?.error) {
  <h2 class="fw-bold text-primary fs-body-md font-secondary">{{ 'app-inzichtcode-toevoegen-titel' | fromDictionary }}</h2>
  <p class="lh-base">
    Uw inzichtcode:
    <strong>{{ insightCode$() }}</strong>
  </p>

  <div class="text-center">
    <button class="button button--secondary" (click)="clear()">
      {{ 'app-inzichtcode-verwijderen-knoptekst' | fromDictionary }}
    </button>
  </div>
} @else {
  <h2 class="fw-bold text-primary fs-body-md font-secondary">{{ 'app-inzichtcode-toevoegen-titel' | fromDictionary }}</h2>
  <p class="lh-base">{{ 'app-inzichtcode-toevoegen-tekst' | fromDictionary }}</p>

  @if (formFields$().length > 0) {
    <fstr-dynamic-form
      [config]="formFields$()"
      (formSubmit)="save(address$()!, $event.insightCode)"
      [appearance]="'fill'"
      [showSubmit]="false"
      #form
    >
      <div class="text-center" slot="form-actions">
        @if (error$()) {
          <app-alert class="d-block mt-4" [status]="'danger'" [message]="error$()?.error?.errors?.[0].description"></app-alert>
        }
        @if (!!form.form.value.insightCode) {
          <button type="submit" class="button button--secondary mt-3" [disabled]="!form.form.valid">
            {{ 'app-inzichtcode-opslaan-knoptekst' | fromDictionary }}
          </button>
        }
      </div>
      <div class="text-center mt-3" slot="form-actions">
        <a href="/mijn-rova/aanmelden/code-vergeten" class="button button--secondary button-link text-decoration-underline">
          Code vergeten
        </a>

        <hr class="mt-2 mb-3 border-secondary-100" />
        <div class="d-flex gap-3 flex-wrap align-items-center justify-content-center">
          <strong>{{ 'inzicht-code-formulier-aanmelden-intro' | fromDictionary }}</strong>
          <a href="/mijn-rova/aanmelden/registreren" class="button button--primary">Aanmelden</a>
        </div>
      </div>
    </fstr-dynamic-form>
  }
}
